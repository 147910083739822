
#auth-sider .logo {
  height: 54px;
  width: 100px;
  margin: 4px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}